import Image from 'next/image';
import { loaderImage } from 'utils/image';
import { loaderStaticImage } from 'utils/image';

const backgroundImage = loaderStaticImage({
  src: 'static/images/organic/signup-modal-image-abstract.png'
});

const photoImage = loaderStaticImage({
  src: 'static/images/organic/signup-modal-image-woman.png'
});

export function ModalSidebarAbstract() {
  return (
    <>
      <div className="signup-content signup-content--abstract">
        <h3>
          Poucos passos, <span className="nowrap">muitas possibilidades</span>
        </h3>

        <ul>
          <li>
            <span>1</span>
            <span>
              Preencha os campos ao lado pra <strong>começar grátis</strong>
            </span>
          </li>
          <li>
            <span>2</span>
            <span>
              Junte-se a outras 300 mil pessoas que{' '}
              <strong>vendem com a gente!</strong>
            </span>
          </li>
          <li>
            <span>3</span>
            <span>
              Crie um produto dentro dos mais de 15 formatos e{' '}
              <strong>ganhe dinheiro ensinando algo que você já domina</strong>
            </span>
          </li>
        </ul>
      </div>

      <Image
        src={backgroundImage}
        loader={loaderImage}
        alt="Fundo laranjado"
        height={642}
        width={480}
        className="signup-content__image"
      />
    </>
  );
}

export function ModalSidebarPhoto() {
  return (
    <>
      <div className="signup-content signup-content--photo">
        <h3>
          Poucos passos, <span className="nowrap">muitas possibilidades</span>
        </h3>

        <ul>
          <li>
            <span>1</span>
            <span>
              Preencha os campos ao lado pra <strong>começar grátis</strong>
            </span>
          </li>
          <li>
            <span>2</span>
            <span>
              Junte-se a outras 300 mil pessoas que{' '}
              <strong>vendem com a gente!</strong>
            </span>
          </li>
          <li>
            <span>3</span>
            <span>
              Crie um produto dentro dos mais de 15 formatos e{' '}
              <strong>ganhe dinheiro ensinando algo que você já domina</strong>
            </span>
          </li>
        </ul>
      </div>

      <Image
        src={photoImage}
        loader={loaderImage}
        alt="Elaine Lourença"
        height={642}
        width={480}
        priority={true}
        className="signup-content__image"
      />
    </>
  );
}
