import { useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { ModalSidebarPhoto } from 'components/Modal/ModalSidebar';
import { loaderStaticImage } from 'utils/image';
import IconsImage from 'components/Images/Icons';
import config from '../../../../env.config';

type Props = {
  isOpen: boolean;
};

let closeModal = loaderStaticImage({
  src: 'static/images/organic/close-modal.svg'
});

let hotmartLogo = loaderStaticImage({
  src: 'static/images/organic/hotmart-logo-modal.svg'
});

function ModalSignup({ isOpen }: Props) {
  const marketplaceContext = useContext(ProfileContext);
  const router = useRouter();
  const { locale, asPath } = router;
  const { actions, isModalOpen } = marketplaceContext;
  const element = 'modal';

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actions.setIsModalOpen(!isModalOpen);
      }
    };

    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }

    window.addEventListener('keydown', handleKeyDown);
  }, [isModalOpen]);

  return (
    <div
      id="modal-signup"
      className={`modal-backdrop ${
        isModalOpen ? 'modal-backdrop--active' : ''
      }`}
    >
      <div className="modal">
        <button
          className="modal__button"
          type="button"
          onClick={() => actions.setIsModalOpen(!isModalOpen)}
        >
          <IconsImage src={closeModal} width={24} height={24} />
        </button>

        <div className="modal__container">
          <div className="modal__content">
            <div className="modal__title">
              <IconsImage
                src={hotmartLogo}
                width={80}
                height={26}
                className="modal__logo"
              />
              <h2>Comece na Hotmart gratuitamente</h2>
              <p>
                Crie uma conta e inicie seu projeto na maior plataforma de
                produtos digitais do mundo.
              </p>
            </div>

            <div className="modal__iframe">
              <iframe
                id="signup-iframe"
                width="100%"
                height="100%"
                src={`${
                  config.envConfig.SSO_ENDPOINT
                }?variant&noLogo&nofooter&transparent&embed&locale=${locale}&service=${encodeURIComponent(
                  `https://app.${config.envConfig.HOST}/`
                )}&isEmbed=true&element=${element}&url=${
                  config.envConfig.SSO_ENDPOINT
                }?variant&noLogo&nofooter&transparent&embed&locale=${locale}&referrer=${
                  config.envConfig.APP
                }${asPath}`}
                title="Signup Marketplace"
                sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              />
            </div>
          </div>

          <div className="modal__sidebar">
            <ModalSidebarPhoto />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSignup;
